import { breakpoints } from "../Media"

export const theme = {
  colors: {
    main: "#F46E67",
    text: "#4C4C4C",
    background: "#F3EBD7",
  },
  mediaQueries: {
    mobile: `min-width: ${breakpoints.mobile}px`,
    tablet: `min-width: ${breakpoints.tablet}px`,
    desktop: `min-width: ${breakpoints.desktop}px`,
    largeDesktop: `min-width: ${breakpoints.largeDesktop}px`,
  },
}
